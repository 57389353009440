import { useState, useCallback } from "react";
import axios from "axios";

const serverUrl = process.env.REACT_APP_API_URL;

const useSettingAndConfig = () => {
  const [globalSettingMap, setGlobalSettingMap] = useState(null);
  const [dashboardQMS, setDashboardQMS] = useState("A");
  const [serverVersion, setServerVersion] = useState("");

  const fetchSettingAndConfig = useCallback(async () => {
    try {
      const [res1, res2, res3] = await Promise.all([
        axios.get(serverUrl + "/globalsetting/allitem"),
        axios.get(serverUrl + "/qms/setting/dashboard"),
        axios.get(serverUrl + "/version"),
      ]);

      const { globalSetting } = res1.data;
      const globalSettingMap = new Map(
        globalSetting.map((item) => [item.setting_type, item.setting]),
      );
      setGlobalSettingMap(globalSettingMap);

      const { dashboard } = res2.data;
      setDashboardQMS(dashboard.dashboard_display);

      const serverVersion = res3.data;
      setServerVersion(serverVersion);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return {
    globalSettingMap,
    dashboardQMS,
    serverVersion,
    fetchSettingAndConfig,
  };
};

export default useSettingAndConfig;
