import { useState, createContext, useContext, useEffect, useCallback } from "react";
import AuthAPI from "../utils/api";
import jwtDecode from "jwt-decode";
import { checkPermissionRoute } from "../utils/permission";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const [permissionRouteLIS, setPermissionRouteLIS] = useState({
    canAccessMainLab: false,
    canAccessLabRequest: false,
    canAccessReport: false,
    canAccessReportHistory: false,
    canAccessCheckup: false,
    canAccessHospitalNet: false,
    canAccessRiskManagement: false,
  });
  const [permissionRouteQMS, setPermissionRouteQMS] = useState({
    canAccessRegister: false,
    canAccessKiosk: false,
    canAccessQueue: false,
    canAccessDashboard: false,
    canAccessReport: false,
  });
  const [permissionRouteSetting, setPermissionRouteSetting] = useState({
    canAccessSettingLIS: false,
    canAccessSettingQMS: false,
    canAccessSettingAccount: false,
    canAccessSettingScript: false,
    canAccessSettingClient: false,
  });

  const resetUserState = () => {
    setUserIsLoggedIn(false);
    setUserData(null);
    setIsSuperAdmin(false);
  };

  const login = async (username, password) => {
    let returnMessage = { message: "", error: null };

    try {
      // step 1: Authentication
      const data = { v1: username, v2: password };
      const response = await AuthAPI.login(data);
      const { message, accessToken } = response.data;
      returnMessage.message = message;

      if (message === "success") {
        localStorage.setItem("token", accessToken);
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
      returnMessage.error = error;
    } finally {
      return returnMessage;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    resetUserState();
  };

  const verifyToken = useCallback(async () => {
    let returnMessage = { isValidToken: false, message: "", error: null };

    const handleInvalidToken = () => {
      localStorage.removeItem("token");
      resetUserState();
      returnMessage.isValidToken = false;
    };

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        handleInvalidToken();
      } else {
        // step 1: Verify Token
        const res1 = await AuthAPI.verifyToken();
        const data = res1.data;
        if (data === "Token is vaild!") {
          returnMessage.isValidToken = true;

          // step 2: Authorization
          const res2 = await AuthAPI.getUserPermission();
          const { message, permissionList, level } = res2.data;

          setUserIsLoggedIn(true);
          setUserData({ ...jwtDecode(token), level, permission: permissionList || [] });
          setIsSuperAdmin(level === "superadmin");

          if (level !== "superadmin") {
            const routePermission = checkPermissionRoute(permissionList || []);
            setPermissionRouteLIS(routePermission.permissionLIS);
            setPermissionRouteQMS(routePermission.permissionQMS);
            setPermissionRouteSetting(routePermission.permissionSetting);
          }

          if (message === "success") {
            if (permissionList.length === 0 && level !== "superadmin") {
              returnMessage.message = "You don't have permission to access";
            }
          } else {
            returnMessage.message = message;
          }
        } else {
          handleInvalidToken();
        }
      }
    } catch (error) {
      console.log(error);
      returnMessage.error = error;
    } finally {
      return returnMessage;
    }
  }, []);

  useEffect(() => {
    let checkTokenExpInterval = null;
    let verifyTokenInterval = null;

    if (userIsLoggedIn) {
      // const token = localStorage.getItem("token");
      // if (token) {
      //   const decoded = jwtDecode(token);
      //   setTimeout(() => {
      //     localStorage.removeItem("token");
      //     window.location.href = "/signin";
      //   }, decoded.exp * 1000 - Date.now());
      // }

      checkTokenExpInterval = setInterval(() => {
        const token = localStorage.getItem("token");
        try {
          if (token) {
            const decoded = jwtDecode(token);
            if (decoded.exp * 1000 < Date.now()) {
              throw new Error("Token has expired");
            }
          } else {
            throw new Error("Token not found");
          }
        } catch (error) {
          verifyToken();
        }
      }, 1000);

      verifyTokenInterval = setInterval(() => {
        verifyToken();
      }, 30000);
    }

    return () => {
      clearInterval(checkTokenExpInterval);
      clearInterval(verifyTokenInterval);
    };
  }, [userIsLoggedIn, verifyToken]);

  return (
    <AuthContext.Provider
      value={{
        userIsLoggedIn,
        userData,
        isSuperAdmin,
        permissionRouteLIS,
        permissionRouteQMS,
        permissionRouteSetting,
        login,
        logout,
        verifyToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
