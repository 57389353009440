import axios from "axios";

axios.defaults.validateStatus = (status) => {
  return status < 500 && status !== 401;
};

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    const headers = response.headers;
    const refreshToken = headers["x-refresh-token"];
    if (refreshToken) {
      localStorage.setItem("token", refreshToken);
    }
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 ||
        error.response.data === "No Authorization" ||
        error.response.data === "No Authorization (Invalid Token)")
    ) {
      localStorage.removeItem("token");
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  },
);
