import axios from "axios";

const serverUrl = process.env.REACT_APP_API_URL;

const AuthAPI = {
  login: async (data) => {
    const response = await axios.post(serverUrl + "/auth/login", data);
    return response;
  },
  verifyToken: async () => {
    const response = await axios.post(serverUrl + "/verifyToken");
    return response;
  },
  getUserPermission: async () => {
    const response = await axios.get(serverUrl + "/userlevel/permission");
    return response;
  },
};

export default AuthAPI;
