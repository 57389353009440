import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import './assets/css/mainstyle.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import "./config/axios.config";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./features/Auth/Contexts/AuthProvider";

ReactDOM.render(
  //<React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
  //</React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
